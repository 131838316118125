<!-- =========================================================================================
  File Name: VideoView.vue
  Description: Video View page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/video/pixinvent
========================================================================================== -->

<template>
  <div id="page-video-view">

    <vs-alert :active.sync="video_not_found" color="danger" title="Video Not Found">
      <span>Video record with id: {{ $route.params.videoId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link :to="{name:'page-video-list'}" class="text-inherit underline">All Videos</router-link>
      </span>
    </vs-alert>


    <div v-if="video_data" id="video-data">
      <div class="vx-row">

        <div class="vx-col lg:w-1/5 w-full">
          <vx-card class="mb-base" style="height:95%" title="Video Actions">
            <!-- Avatar -->
            <div class="vx-row">

              <div v-if="video_data.public" class="vx-col w-full flex pt-3">
                <vs-button v-clipboard:copy="`https://watch.cloudflarestream.com/${video_data.cloudflare_id}`"
                           class="w-full" color="secondary" icon="icon-paperclip" icon-pack="feather"
                           style="height:2rem;">
                  Copy Embed
                </vs-button>
              </div>

              <div class="vx-col w-full flex pt-3">
                <vs-button :to="`/a/videos/${$route.params.videoId}/edit`" class="w-full" color="warning"
                           icon="icon-edit" icon-pack="feather" style="height:2rem;">
                  Edit
                </vs-button>
              </div>

              <!-- /Information - Col 2 -->
              <div class="vx-col w-full flex pt-3">
                <vs-button class="w-full" color="danger" icon="icon-archive" icon-pack="feather" style="height:2rem;"
                           @click="deleteVideo">
                  Delete
                </vs-button>
              </div>

            </div>
          </vx-card>
        </div>

        <div class="vx-col lg:w-2/5 w-full">
          <vx-card class="mb-base" style="height:95%" title="Extended Information">
            <table>
              <tr>
                <td class="font-semibold">Video Name</td>
                <td>{{ video_data.name }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Video Is Public?</td>
                <td>{{ video_data.public ? 'Yes' : 'No' }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Video Duration</td>
                <td>{{ video_data.duration | duration }}</td>
              </tr>
              <vs-divider/>
              <tr>
                <td class="font-semibold">Video Platform</td>
                <td>{{ video_data.platform | title }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Video Id</td>
                <td>{{ (video_data.cloudflare_id || video_data.osidex_id) }}</td>
              </tr>
            </table>
          </vx-card>
        </div>

        <div class="vx-col lg:w-2/5 w-full">
          <vx-card class="mb-base" style="height:95%" title="Video Thumbnail">
            <!-- Avatar -->
            <div class="vx-row">

              <!-- Avatar Col -->
              <div v-if="video_data.thumbnail_id" id="avatar-col" class="vx-col">
                <div class="img-container">
                  <img :src="video_data.photoURL" class="rounded w-full"/>
                </div>
              </div>

            </div>
          </vx-card>
        </div>


      </div>


    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      video_data: null,
      video_not_found: false,
      attributes: {},

      treeData: [],
    };
  },
  methods: {
    deleteVideo() {
      return this.$vs.notify({
        title: 'Failed to Delete Video',
        text: 'We do not currently support video deletion',
        color: 'danger',
      });
    },
    setPhotoUrl() {
      this.video_data.photoURL = `https://app-cdn.rapidseminars.com/thumbnails/${
        this.video_data.thumbnail_id || 'default'
      }.jpeg`;
    },
    loadMeta() {

    },
    showError(exception) {

      let error = 'An unknown error occurred while modifying this video';
      if (exception.response) {
        error = exception.response.data.data.message;
      }

      return this.$vs.notify({
        title: 'Failed to update video',
        text: error,
        color: 'danger',
      });
    },
  },
  created() {

    const videoId = this.$route.params.videoId;

    this.$http.get(`videos/${videoId}`)
      .then(response => {

        if (response.data.data) {
          this.video_data = response.data.data;
          this.setPhotoUrl();
        }

      })
      .catch(error => {
        console.error(error);

        if (error.response && error.response.status === 404) {
          this.video_not_found = true;
          return;
        }
      });
  },
};

</script>

<style lang="scss">
#avatar-col {
  width: 100%;
}

#page-video-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}
</style>
